import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    background-color: #fdfbfc;

    font-family: Verdana, Geneva, Tahoma, sans-serif

    width: 100vw;
  }

  *, ** {
    box-sizing: content-box;
  }
`;

export default GlobalStyle;
