import styled, { keyframes } from 'styled-components';

export const WrapIcon = styled.div`
  svg {
    width: 30px;
    padding-bottom: 2px;
  }
`;

export const WrapOpen = styled.div`
  padding: 5px;
  position: absolute;
  bottom: 10px;
  right: 20px;

  .btn {
    padding: 5px;
    line-height: 1;
  }

  svg {
    width: 15px;
  }
`;

export const WrapItemText = styled.button`
  background: none;
  border: none;

  width: 100%;
  height: 230px;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    height: 240px;
  }
`;

export const WrapCarouselItem = styled.div`
  position: relative;
  width: 100%;
  height: 240px;

  padding: 0 10px;
  display: flex;

  iframe {
    height: 100%;
    width: 100%;
  }

  .lazyload-wrapper {
    height: 100%;
    width: 100%;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
`;
