import styled from 'styled-components';

import Logo from '../../assets/logo.png';

export const Page = styled.div`
  margin-top: 20px;
`;

// export const Push = styled.div`
//   height: 20px;
//   width: 1px;
//   position: absolute;
//   bottom: 21px;
//   left: 50%;
//   transform: translateX(-50%);
//   background: black;
// `;

// export const HeroBanner = styled.div`
//   min-height: 100vh;

//   @media (min-width: 768px) {
//     min-height: auto;
//   }
// `;

export const Image = styled.img.attrs({
  src: Logo,
})`
  width: 100%;
`;

export const WrapImage = styled.div`
  margin: auto;
  width: 150px;
`;

export const SectionTitle = styled.h2`
  color: #033d60;
  font-size: 20px;
  margin-bottom: 30px;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: bloack;

    height: 1px;
    width: 100%;

    position: absolute;
    left: 0;
    bottom: -5px;

    background-color: #033d60;
  }

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

export const BodyText = styled.p`
  color: #3e3e3e;
  font-size: 16px;
  text-align: justify;

  font-weight: 400;
  line-height: 1.5;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const Section = styled.section`
  margin: 50px 0;
`;

export const CardContent = styled.h2`
  padding: 20px;

  ${BodyText} {
    margin-bottom: 0;
  }
`;

export const WrapCard = styled.h2`
  display: flex;
`;

export const Title = styled.h2`
  color: #033d60;
  font-size: 24px;

  margin-top: 20px;
  margin-bottom: 50px;

  text-align: center;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;
