import { useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import LazyLoad from 'react-lazyload';

import ArrowRight from '../../assets/icons/arrowRight';
import OpenIcon from '../../assets/icons/open';
import { BodyText } from '../../pages/main/styles';
import * as S from './styles';

const RenderItem = ({ item, onClick }) => {
  const refPlaceholder = useRef();

  const removePlaceholder = () => {
    refPlaceholder?.current?.remove();
  };

  useEffect(() => {
    if (item.tipo === 'video') {
      removePlaceholder();
    }
  }, [item]);

  return (
    <S.WrapCarouselItem>
      <S.Placeholder ref={refPlaceholder} />
      {item.tipo === 'image' && (
        <LazyLoad>
          <S.Image
            src={item.thumbnail}
            alt="construcao"
            onLoad={removePlaceholder}
            onError={removePlaceholder}
          />
        </LazyLoad>
      )}
      {item.tipo === 'video' && (
        <iframe
          src={`${item.link_do_youtube}?modestbranding=0&autohide=1&showinfo=0`}
          title="YouTube video player"
          frameborder="0"
          allow="autoplay; encrypted-media;"
          allowfullscreen
        ></iframe>
      )}
      {item.tipo === 'text' && (
        <S.WrapItemText onClick={onClick}>
          <BodyText>Ver mais</BodyText>
          <S.WrapIcon>
            <ArrowRight />
          </S.WrapIcon>
        </S.WrapItemText>
      )}

      {item.tipo === 'image' ? (
        <S.WrapOpen>
          <Button color="primary" onClick={onClick}>
            <OpenIcon />
          </Button>
        </S.WrapOpen>
      ) : (
        <></>
      )}
    </S.WrapCarouselItem>
  );
};

export default RenderItem;
