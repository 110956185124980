import { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Alert, Card } from 'reactstrap';

import * as S from './styles';
import Carousel from '../../Components/Carousel';

const Main = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    'inicio-da-obra': [],
    andamento: [],
    'projeto-de-construcao': [],
    'igreja-antiga': [],
  });

  useEffect(() => {
    axios
      .get(
        'https://centralapi.congregacaoitapetininga.com.br/api_admin/api/projeto'
      )
      .then(({ data }) => {
        let format = {};
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          format[element.id] = element.photos;
        }

        setData(format);
      });
  }, []);

  return (
    <S.Page>
      <Container>
        {/* <S.HeroBanner> */}
        <S.WrapImage>
          <h1>
            <S.Image />
          </h1>
        </S.WrapImage>

        <S.Title>
          Construção da Congregação Cristã no Brasil Central de Itapetininga
        </S.Title>

        <Alert>
          <h3>Atenção!</h3>
          <S.BodyText>
            Este não é o site oficial da Congregação Cristã no Brasil. <br />O
            site oficial é{' '}
            <a
              href="https://www.congregacaocristanobrasil.org.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              este aqui
            </a>
            .<br /> Este site, visa mostrar à irmandade o desenvolvimento da
            construção e o projeto de construção.
          </S.BodyText>
        </Alert>

        {/* <S.Push></S.Push> */}
        {/* </S.HeroBanner> */}
        <S.Section>
          <S.SectionTitle>Casa de Oração Centro</S.SectionTitle>

          <Carousel items={data} index="inicio-da-obra" />

          <S.BodyText>
            Essa Casa de Oração está localizada em Itapetininga, SP. Esta foi
            uma das primeiras contruídas na cidade.
          </S.BodyText>
          <S.BodyText>
            A primeira versão, foi levantada há aproximadamente 50 anos, e em
            2010 foi realizada uma grande reforma estrutural. Em 2020, foi
            detectada diversas rachaduras no telhado, na qual nossos irmãos
            engenheiros fizeram a vistoria no local e foi constato risco de
            queda do telhado.
          </S.BodyText>
          <S.BodyText>
            Desde então, os cultos foram transferidos para a Casa de Oração da
            Vila Arruda e na Vila Rio Branco.
          </S.BodyText>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Como era antes</S.SectionTitle>
          <Carousel items={data} index="igreja-antiga" />
        </S.Section>

        <S.Section>
          <S.SectionTitle>Projeto da nova construção</S.SectionTitle>
          <Carousel items={data} index="projeto-de-construcao" />
        </S.Section>

        <S.Section>
          <S.SectionTitle>Demolição da igreja antiga</S.SectionTitle>
          <Carousel items={data} index="demolicao-da-igreja" />
        </S.Section>

        <S.Section>
          <S.SectionTitle>Andamento da Construção</S.SectionTitle>
          <Carousel items={data} index="andamento" />
        </S.Section>

        <S.Section>
          <S.SectionTitle>Oferta</S.SectionTitle>
          <S.BodyText>
            Nessa etapa, necessitamos muito do apoio da cara irmandade. Caso
            tenha o sentimento de ofertar qualquer valor, abaixo está a conta
            corrente da Congregação Cristã no Brasil.
          </S.BodyText>
          <S.WrapCard>
            <Card>
              <S.CardContent>
                <S.BodyText>
                  <strong>Banco Santander</strong>
                </S.BodyText>
                <br />
                <S.BodyText>
                  <strong>Favorecido:</strong> CONGREGAÇÃO CRISTÃ NO BRASIL
                  ITAPETININGA
                </S.BodyText>
                <S.BodyText>
                  <strong>CNPJ</strong>: 45.445.079/0001-00
                </S.BodyText>
                <S.BodyText>
                  <strong>Agência</strong>: 0017
                </S.BodyText>
                <S.BodyText>
                  <strong>Conta corrente</strong>: 13001556-1
                </S.BodyText>
                <S.BodyText>
                  <strong>Chave pix</strong>: 45.445.079/0001-00
                </S.BodyText>
              </S.CardContent>
            </Card>
          </S.WrapCard>
        </S.Section>

        <S.Section>
          <S.SectionTitle>Quem somos</S.SectionTitle>
          <S.BodyText>
            A CONGREGAÇÃO CRISTÃ é uma comunidade religiosa inteiramente
            fundamentada na doutrina e Fé apostólicas contidas no Novo
            Testamento da Bíblia Sagrada.
          </S.BodyText>

          <S.BodyText>
            O exercente de qualquer cargo espiritual ou de administração deverá
            manter-se através de seu trabalho ou meios próprios, uma vez que é
            vedada qualquer espécie de remuneração ou retribuição pelo exercício
            dessas atividades ou pela ministração de serviços espirituais ou
            sacramentos.
          </S.BodyText>

          <S.BodyText>
            A CONGREGAÇÃO CRISTÃ não tem qualquer vínculo com partidos ou
            ideologias políticas e os integrantes de cargos espirituais, ou de
            administração, devem se abster de aceitar cargos ou encargos
            políticos, sendo incompatível o exercício concomitante de funções em
            ambas as esferas (cargo ou encargo político e função espiritual ou
            administrativa na igreja). Recomenda-se aos membros cumprir os
            deveres cívicos de cidadãos, consoante as leis do país.
          </S.BodyText>
        </S.Section>
      </Container>
    </S.Page>
  );
};

export default Main;
